import React from "react";
import PropTypes from 'prop-types';


export const N3WalletContext = React.createContext({});
export const N3WalletContextProvider = ({ children }) => {


    const [neoLine, setNeoLine] = React.useState(null)
    const [neoLineN3, setNeoLineN3] = React.useState(null)
    const [connected, setConnected] = React.useState(false)
    const [account, setAccount] = React.useState({
        network: null,
        address: null,
        provider: null
    })

    const initNeoLine = async () => {
        const onReady = async () => {
            try {
                const neoDapi = new window.NEOLine.Init();
                const neoDapiN3 = new window.NEOLineN3.Init();
                setNeoLine(neoDapi)
                setNeoLineN3(neoDapiN3)
            } finally {

            }
        };

        if (window.NEOLine != null && window.NEOLineN3 != null) {
            onReady();
        }
        window.addEventListener('NEOLine.NEO.EVENT.READY', () => {
            if (window.NEOLine != null && window.NEOLineN3 != null) {
                onReady();
            }
        });
        window.addEventListener('NEOLine.N3.EVENT.READY', () => {
            if (window.NEOLine != null && window.NEOLineN3 != null) {
                onReady();
            }
        });
    };

    // const init = React.useCallback(async () => {
    //     console.log("init N3WalletContext")
    // }, []);

    // React.useEffect(() => {
    //     init()
    // }, [])

    const invoke = async (args) => {
        return neoLineN3.invoke(args)
    }

    const getInfo = async () => {
        let defaultNetwork = await neoLine.getNetworks()
            .then(result => {
                const {
                    chainId,
                    networks,
                    defaultNetwork
                } = result;
                return defaultNetwork
            })

        let address = await neoLine.getAccount()
            .then(account => {
                const {
                    address,
                    label
                } = account;
                return address
            })



        let provider = await neoLineN3.getProvider()
            .then(provider => {
                const {
                    name,
                    website,
                    version,
                    compatibility,
                    extra
                } = provider;
                // console.log('Provider name: ' + name);
                // console.log('Provider website: ' + website);
                // console.log('Provider dAPI version: ' + version);
                // console.log('Provider dAPI compatibility: ' + JSON.stringify(compatibility));
                // console.log('Extra provider specific atributes: ' + JSON.stringify(compatibility));
                return provider;
            })

        setConnected(true)
        setAccount({
            network: defaultNetwork,
            address: address,
            provider: provider,
        })
    }

    React.useEffect(() => {
        if (neoLine !== null && neoLineN3 !== null) {
            getInfo()
        }
    }, [neoLine, neoLineN3])

    const contextValue = {
        neoLine,
        neoLineN3,
        initNeoLine,
        connected,
        account,
        invoke
    };

    return (React.createElement(N3WalletContext.Provider, { value: contextValue }, children));
};

export const useN3WalletContext = () => React.useContext(N3WalletContext);
N3WalletContextProvider.propTypes = {
    children: PropTypes.any.isRequired,
};