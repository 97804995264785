
import React from "react";
import {
  Routes,
  Route,
} from "react-router-dom";

import Home from "./Home";
import { WalletConnectProvider } from "@cityofzion/wallet-connect-sdk-react";
import HomeV2 from "./Home/HomeV2";
import { N3WalletContextProvider } from "./contexts/N3WalletContext";

const wcOptions = {
  projectId: '6f9723d01cbe512c13abda8a4cb70b36',  //Neo Mystery Box on https://cloud.walletconnect.com/
  relayUrl: 'wss://relay.walletconnect.com', 
  metadata: {
    name: 'Neo Gift App',
    description: 'Neo Gift App',
    url: 'https://gift.neo.org/',
    icons: ['https://gift.neo.org/present.svg']
  }
};

function App() {
  return (
    <N3WalletContextProvider>
    <WalletConnectProvider autoManageSession={true} options={wcOptions}>
      <Routes>
        <Route path="/old" element={<Home />}></Route>
        <Route path="/" element={<HomeV2 />}></Route>
      </Routes>
    </WalletConnectProvider>
    </N3WalletContextProvider>
  )
}

export default App;
