import { Transition } from "@headlessui/react";
import { Fragment } from "react";

export function FadeInDialog(props) {
    const { isPresented, setIsPresented } = props
    return (
        <Transition
            show={isPresented}
            as={Fragment}
            enter="ease-out duration-300 delay-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <div onClick={() => { setIsPresented && setIsPresented(false) }} className={`fixed top-0 left-0 w-full h-full bg-black/90 flex items-center justify-center z-[999] p-8 transition-opacity opacity-0`}>
                {props.children}
            </div>
        </Transition>
    )
}