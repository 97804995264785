export default function LogoOnTree(props) {
    const { image, name, url, className } = props
    return (
        <a href={url} target="_blank" className={`group absolute rounded-full w-10 h-10 bg-white ring-4 ring-white ring-opacity-40 flex items-center justify-center ${className}`}>
            <img src={image} className="w-6 h-6 object-contain" />
            {
                name ?
                    <div className="text-secondary text-xs duration-200 ease-in-out opacity-0 group-hover:opacity-100 bg-white rounded drop-shadow-lg p-2 absolute bottom-full -translate-y-2">
                        <p className="whitespace-nowrap">{name}</p>
                    </div>
                    : null
            }

        </a>
    )
}