"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WitnessRule = exports.WitnessRuleAction = void 0;
const internal_1 = require("../../internal");
const WitnessCondition_1 = require("./WitnessCondition");
var WitnessRuleAction;
(function (WitnessRuleAction) {
    WitnessRuleAction[WitnessRuleAction["Deny"] = 0] = "Deny";
    WitnessRuleAction[WitnessRuleAction["Allow"] = 1] = "Allow";
})(WitnessRuleAction = exports.WitnessRuleAction || (exports.WitnessRuleAction = {}));
class WitnessRule {
    constructor(input = {}) {
        this.action =
            input.action !== undefined
                ? (0, internal_1.parseEnum)(input.action, WitnessRuleAction)
                : WitnessRuleAction.Deny;
        this.condition =
            input.condition !== undefined
                ? input.condition instanceof WitnessCondition_1.WitnessCondition
                    ? input.condition
                    : WitnessCondition_1.WitnessCondition.fromJson(input.condition)
                : new WitnessCondition_1.CalledByEntryWitnessCondition();
    }
    get size() {
        return 1 + this.condition.size;
    }
    static deserialize(ss) {
        const action = (0, internal_1.parseEnum)(parseInt(ss.read(1), 16), WitnessRuleAction);
        const condition = WitnessCondition_1.WitnessCondition.deserialize(ss);
        return new WitnessRule({ action, condition });
    }
    static fromJson(input) {
        return new WitnessRule(input);
    }
    serialize() {
        return (this.action.toString(16).padStart(2, "0") + this.condition.serialize());
    }
    toJson() {
        return {
            action: WitnessRuleAction[this.action],
            condition: this.condition.toJson(),
        };
    }
}
exports.WitnessRule = WitnessRule;
