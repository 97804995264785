import React from "react"
import "../assets/snow.css"
import { useWalletConnect } from "@cityofzion/wallet-connect-sdk-react";
import { XMarkIcon } from '@heroicons/react/24/solid'

import LeftShadow from '../assets/left_shadow.svg'


import confetti from "canvas-confetti";
import { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import NeonWallet from '../assets/neon.svg'
import O3Wallet from '../assets/o3.png'
import NeoLineWallet from '../assets/neoline.png'
import Tree from "../Components/Tree";
import Giftbox from "../Components/Giftbox";
import LightLeft from "../assets/lights_left.svg"
import Fireplace from "../assets/fireplace.svg"

import Box from "../Components/Box";
import Lottie from "lottie-react";
import fire from "../assets/firelottie.json"
import LoadingAnimation from "../assets/loading.json"
import ChairLeft from "../assets/ChairLeft.svg"
import ChairRight from "../assets/ChairRight.svg"
import { Chest, types } from '@cityofzion/props'
import { BaseNeoDapi, NeoDapi } from "@neongd/neo-dapi";
import { FadeInDialog } from "../Components/FadeInDialog";
import { GiftIcon, XCircleIcon } from "@heroicons/react/24/outline";


export default function Home(props) {

  const puppetContract = "0x76a8f8a7a901b29a33013b469949f4b08db15756" //mainnet
  const giftboxKey = "" //TODO: get this from Tyler. It's a chestId like in the chest app

  const wcSdk = useWalletConnect()
  const [dapi, setDapi] = React.useState(null)

  const [isEligible, setIsEligible] = React.useState(false)
  const [isGiftOpened, setIsGiftOpened] = React.useState(false)
  const [isWalletOptionsPresented, setIsWalletOptionsPresented] = React.useState(false)
  const [address, setAddress] = React.useState(null)
  const [nep11Balances, setNep11Balances] = React.useState(null)
  const [balances, setBalances] = React.useState(null)
  
  const [isAlertPresented, setIsAlertPresented] = React.useState(false)
  const [isLoadingAssets, setIsLoadingAssets] = React.useState(false)
  const [isAlertNoAssetsPresented, setIsAlertNoAssetsPresented] = React.useState(false)
  const [showAvailableGifts, setShowAvailableGifts] = React.useState(false)

  const connectWalletConnect = async () => {
    if (!wcSdk.isConnected()) {
      await wcSdk.connect('neo3:mainnet')
      console.log(wcSdk.isConnected() ? 'Connected successfully' : 'Connection refused')
    }
  }

  const connectWalletDApi = () => {
    // loadDapi()
  }

  const start = async () => {
    const address = wcSdk.getAccountAddress()
    //1. Checking user's asset 
    //2. If user has nep11 puppet then check if what key user has
    //3. get token id and check eligibility
    console.log("Checking assets of address ", address)
    setAddress(address)
    loadAssets(address)
  }

  // const loadDapi = React.useCallback(async () => {
  //   if (!dapi) {
  //     const d = window.neo
  //       ? new BaseNeoDapi(window.neo)
  //       : window.OneGate
  //         ? new BaseNeoDapi(window.OneGate)
  //         : window.Vital
  //           ? new BaseNeoDapi(window.Vital)
  //           : window._o3dapi
  //             ? new BaseNeoDapi(window._o3dapi)
  //             : null;
  //     setDapi(d ?? undefined);
  //     console.log(d)
  //     if (d) {
  //       const a = (await dapi.getAccount()).address;
  //       setAddress(a)
  //       console.log(a)
  //       // const networks = (await dapi.getNetworks()).networks;
  //       // setNetworks(networks);
  //       // const defaultNetwork = (await dapi.getNetworks()).defaultNetwork;
  //       // setDefaultNetwork(defaultNetwork);
  //     }
  //   }
  // }, [dapi]);

  //1. load assets after wallet is connected (able to read wallet's address)
  const loadAssets = async (address) => {
    //loading
    setIsLoadingAssets(true)
    const fetchData = url => fetch(url).then(r => r.json()); // 1
    const nep11AssetsUrl = `https://api.ghostmarket.io/api/v1/assets?chain=n3&owner=${address}`
    const balancesUrl = `https://dora.coz.io/api/v1/neo3/mainnet/balance/${address}`

    const [nep11Assets, assets] = await Promise.all([
      fetchData(nep11AssetsUrl),
      fetchData(balancesUrl)
    ]);

    console.log("Assets ", nep11Assets)
    setNep11Balances(nep11Assets)
    setBalances(assets)

    if (nep11Assets.assets === null) {
      setIsAlertNoAssetsPresented(true)
      setTimeout(() => {
        setIsAlertNoAssetsPresented(false)
      }, 2000);
    }
  }

  //2. when we get nep11 balances
  React.useEffect(() => {

    if (address === null) {
      return
    }

    if (nep11Balances === null) {
      setIsLoadingAssets(false)
      setIsEligible(false)
      return
    }

    //if user doesn't have any nep11 meaning that this wallet doesn't have puppet
    if (nep11Balances.assets == null) {
      console.log(address, " doesn't have any asset")
      setIsLoadingAssets(false)
      setIsEligible(false)
      return
    }

    //TODO: check eligible
    setIsEligible(true)
    setIsLoadingAssets(false)
    console.log(nep11Balances)
    //a user can have multiple gift in nep11 balances. We should show a list to let a user pick

    //TODO check this
    // const attributeName = "traits.trade"
    // const attributeValue = "blacksmith"
    // let xMasKeys = nep11Balances.assets.filter((a) => (a.nft.contract === puppetContract && a.nft.nft_metadata.attributes.some(attribute => (attribute.name === attributeName && (attribute.value === attributeValue)))))

    // if (xMasKeys.length > 0) {

    //   const asset = xMasKeys[0];
    //   const symbol = asset.nft.symbol;
    //   const chain = asset.nft.chain;
    //   const contract = asset.nft.contract;

    //   const metaData = asset.nft.nft_metadata;
    //   var regex = /\#(.*?)\./
    //   //looking for token id inside here
    //   //description: Puppet NFT #4891. 9 of 10000 from the Paper Puppets(3) epoch
    //   const description = metaData.description
    //   var matched = regex.exec(description);
    //   const id = matched[1] //this is a token id that will be used to open the box
    //   setTokenId(id)
    //   //TODO: continue this
    //   // let key = metaData.attributes.filter(attribute => { return attribute.name === attributeName && (attribute.value === attributeValue)})
    //   // const value = key[0].value
    //   // console.log("xMasKeys", key, value, tokenId)

    //   // setPuppetTokenId(tokenId)
    //   // setChestKey(value);
    // }
  }, [nep11Balances])


  const checkIsEligible = async (key, tokenId) => {
    const targetNetwork = types.NetworkOption.MainNet
    const chestId = giftboxKey
    const puppetId = tokenId

    const chest = await new Chest({
      network: targetNetwork
    })
    await chest.init()

    const eligible = await chest.isPuppetEligible(chestId, puppetId)
    // setFinishChecking(true)
    setIsEligible(eligible)
  }

  //0. once connected, we are able to read a user's address then start checking.
  React.useEffect(() => {
    if (!wcSdk.isConnected()) {
      return
    }
    console.log("start")
    start()
  }, [wcSdk.isConnected()])

  const disconnect = async () => {
    if (!wcSdk.isConnected()) {
      return
    }
    //reset all values
    setAddress(null)
    setNep11Balances(null)
    setBalances(null)
    setIsEligible(false)
    wcSdk.disconnect()
  }


  const openGiftBox = async (gift) => {
    //TODO: call props method and wait to show result.

    const tokenId = gift.tokenId;

    

    if (gift.isOpened === true) {
      return;
    }

    setShowAvailableGifts(false)
  
    const list = walletAvailableGifts

    const g = list.find(a => a.tokenId === tokenId)
    g.isOpened = true



    setWalletAvailableGifts(list)
    

    confetti({
      particleCount: 200,
      startVelocity: 80,
      angle: 90,
      spread: 50,
      origin: {
        x: 0.5,
        y: 0.985,
      }
    })

    setIsGiftOpened(true)
  }

  const onClickRedGiftBox = async (e) => {
    
    //if user doesn't connect wallet yet then alert a dialog telling them to connect.
    if (address === null) {
      setIsAlertPresented(true);
      return
    }

    //if user is not eligible then alert
    if (isEligible === false) {
      setIsAlertNoAssetsPresented(true)
      setTimeout(() => {
        setIsAlertNoAssetsPresented(false)
      }, 2000);
      return
    }

    setShowAvailableGifts(true)
  }

  const truncateMiddle = (string) => {
    return `${string.substring(0, 4)}...${string.substring((string.length - 4))}`
  }

  //TODO: filter from nep11 balances
  const [walletAvailableGifts, setWalletAvailableGifts] = React.useState([
    {
      "tokenId": "1",
      "name": "Present 1",
      "isOpened": false
    },
    {
      "tokenId": "2",
      "name": "Present 2",
      "isOpened": false
    },
    {
      "tokenId": "3",
      "name": "Present 3",
      "isOpened": false
    }
  ])

  return (
    <div className="absolute inset-0 w-full h-full bg-secondary flex flex-col overflow-hidden items-center justify-center">


      <div className="w-screen h-screen absolute bottom-36  left-0 flex z-20 overflow-hidden">
        <div className="h-full w-16 flex-none bg-white/[0.01] -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-secondary/0 -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-white/[0.01] -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-secondary/0 -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-white/[0.01] -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-secondary/0 -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-white/[0.01] -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-secondary/0 -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-white/[0.01] -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-secondary/0 -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-white/[0.01] -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-secondary/0 -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-white/[0.01] -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-secondary/0 -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-white/[0.01] -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-secondary/0 -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-white/[0.01] -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-secondary/0 -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-white/[0.01] -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-secondary/0 -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-white/[0.01] -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-secondary/0 -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-white/[0.01] -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-secondary/0 -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-white/[0.01] -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-secondary/0 -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-white/[0.01] -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-secondary/0 -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-white/[0.01] -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-secondary/0 -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-white/[0.01] -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-secondary/0 -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-white/[0.01] -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-secondary/0 -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-white/[0.01] -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-secondary/0 -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-white/[0.01] -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-secondary/0 -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-white/[0.01] -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-secondary/0 -skew-x-12"></div>
        <div className="h-full w-16 flex-none bg-white/[0.01] -skew-x-12"></div>
      </div>

      <Transition
        show={isWalletOptionsPresented}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
        as={Fragment}
      >
        <div className={`fixed top-0 left-0 w-full h-full bg-black/90 flex items-center justify-center z-[999] p-8 transition-opacity opacity-0`}>

          <div className="w-60 ring-1 ring-slate-900 mt-4  bg-secondary text-white rounded-lg shadow-xl flex flex-col items-start divide-y divide-slate-900">
            <div className="px-4 py-4 font-medium w-full flex items-center">
              <p>Connect Wallet</p>
              <button onClick={(e) => setIsWalletOptionsPresented(false)} className="ml-auto"><XMarkIcon className="w-5 h-5" /></button>
            </div>
            <button onClick={(e) => { setIsWalletOptionsPresented(false); connectWalletConnect(e) }} className="py-4 px-4 flex items-center gap-2 w-full hover:bg-black/25">
              <img src={NeonWallet} className="w-8 h-8 rounded" />
              NeonWallet
            </button>
            <button onClick={(e) => { setIsWalletOptionsPresented(false); connectWalletDApi(e) }} className="py-4 px-4 flex items-center gap-2 w-full hover:bg-black/25">
              <img src={O3Wallet} className="w-8 h-8  rounded" />
              O3
            </button>
            <button onClick={(e) => { setIsWalletOptionsPresented(false); connectWalletDApi(e) }} className="py-4 px-4 flex items-center gap-2 w-full hover:bg-black/25">
              <img src={NeoLineWallet} className="w-8 h-8  rounded" />
              NeoLine
            </button>
          </div>
        </div>
      </Transition>

      <nav className="lg:fixed top-0 container max-w-6xl mx-auto flex items-center py-8 px-8 z-[200]">
        <img src="neo-logo.svg" className="h-8" />
        <div className="ml-auto">
          {
            wcSdk.isConnected() ?
              <div className="flex flex-col items-end">
                <div className="text-white flex items-center bg-primary rounded-full px-4 py-2 ring-4 ring-primary ring-opacity-50">
                  <p className="text-sm text-white mr-4">{truncateMiddle(wcSdk.getAccountAddress())}</p>
                  <button onClick={(e) => { disconnect() }} className="flex items-center">
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 16H2.66667C2.22464 16 1.80072 15.8244 1.48816 15.5118C1.17559 15.1993 1 14.7754 1 14.3333V2.66667C1 2.22464 1.17559 1.80072 1.48816 1.48816C1.80072 1.17559 2.22464 1 2.66667 1H6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M11 12.6667L15.1667 8.50004L11 4.33337" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M15.1667 8.5H5.16669" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>

                  </button>
                </div>

              </div>
              :
              <button onClick={(e) => setIsWalletOptionsPresented(true)} className="focus:outline-none bg-primary hover:bg-[#049473] rounded-full text-white font-semibold text-sm py-3 px-6 ring-4 ring-primary ring-opacity-30" >
                Connect Wallet
              </button>


          }

        </div>
      </nav>

      <div className="relative container mx-auto w-full h-full flex gap-4 items-center justify-between text-white px-4 z-[100]">
        <div className="absolute bottom-0 left-0 w-full  flex items-end z-50">

          <div className="w-full flex items-end justify-end pr-12">

            <div className="flex flex-col">
              <Box color="bg-[#FBD06D]" lidW="w-24" w={"w-20"} h={"h-8"} className="" />
              <Box color="bg-[#6E3BDF]" lidW="w-40" w={"w-36"} h={"h-20"} className="" />
            </div>

            <Box color="bg-[#FBD06D]" lidW="w-40" w={"w-36"} h={"h-12"} className="absolute translate-y-10 -translate-x-20 hidden lg:flex" />

            <div className="ml-4">
              <Box color="bg-[#00AF92]" lidW="w-32" w={"w-28"} h={"h-8"} />
              <Box color="bg-[#40FFFA]" lidW="w-40" w={"w-36"} h={"h-12"} />
            </div>
          </div>

          <div className="w-full flex items-end justify-start pl-12">
            <Box color="bg-[#6E3BDF]" lidW="w-40" w={"w-36"} h={"h-20"} className="" />
            <Box color="bg-[#00AF92]" lidW="w-40" w={"w-36"} h={"h-12"} className="absolute translate-y-10 translate-x-20" />
            <div className="ml-4">
              <Box color="bg-[#FBD06D]" lidW="w-32" w={"w-28"} h={"h-8"} />
              <Box color="bg-[#40FFFA]" lidW="w-40" w={"w-36"} h={"h-12"} />
            </div>
          </div>
        </div>

        {/* left */}
        <div className=" hidden lg:flex relative items-center justify-center flex-none w-96 h-full">

          <img src={ChairLeft} className="absolute -bottom-12 z-[100]" />

          <div className="relative w-full p-12">


            <div className="w-full h-full absolute top-0 left-0 p-20 z-50 pointer-events-none">
              <div className=" bg-yellow-100/10 w-full h-full blur-3xl origin-top-left"></div>
              {/* <div className="bg-white absolute top-0 w-[1200px] h-full skew-y-[40deg] origin-top-left"></div> */}
            </div>

            <div className="z-50 ">

              <img src={LightLeft} className="w-full absolute left-0 top-0 z-50 p-12 lg:p-12" />

              <div id="left-window" className=" flex items-center justify-center w-full  bg-[#010225] p-6 z-50">

                <div className="relative flex w-full  bg-white/5 overflow-hidden">

                  <div className="snowing absolute top-0 left-0 w-full pointer-events-none z-10">
                    <div className="small-snow">
                      <div className="small"></div>
                      <div className="small"></div>
                      <div className="medium"></div>
                      <div className="small"></div>
                      <div className="medium"></div>
                      <div className="small"></div>
                      <div className="small"></div>
                      <div className="medium"></div>
                      <div className="small"></div>
                      <div className="medium"></div>
                      <div className="small"></div>
                      <div className="medium"></div>
                      <div className="medium"></div>
                      <div className="medium"></div>
                      <div className="small"></div>
                      <div className="small"></div>
                      <div className="small"></div>
                      <div className="medium"></div>
                      <div className="small"></div>
                      <div className="medium"></div>
                    </div>

                  </div>

                  <img src={LeftShadow} className="w-full absolute left-0 bottom-0" />

                  <div className="flex flex-col z-20 w-full">
                    <div className="w-full aspect-square bg-white/5 border-4 border-secondary"></div>
                    <div className="w-full aspect-square bg-white/5  border-4 border-secondary"></div>
                    <div className="w-full aspect-square bg-white/5 border-4 border-secondary"></div>
                  </div>

                  <div className="flex flex-col z-20 w-full">
                    <div className="w-full aspect-square bg-white/5 border-4 border-secondary"></div>
                    <div className="w-full  aspect-square bg-white/5  border-4 border-secondary"></div>
                    <div className="w-full aspect-square bg-white/5 border-4 border-secondary"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        {/* /left ends */}

        {/* center  */}
        <div className="w-full lg:w-full h-full flex items-end justify-center relative px-4 ">

          <div className="w-full h-full absolute top-0 left-0 p-20 z-20 pointer-events-none">
            <div className=" bg-primary/20 w-full h-full blur-3xl origin-top-left"></div>
            {/* <div className="bg-white absolute top-0 w-[1200px] h-full skew-y-[40deg] origin-top-left"></div> */}
          </div>

          <div id="center-window-on-mobile" className="lg:hidden scale-[80%] absolute z-0 top-0 p-8 bg-secondary flex items-center justify-center w-full">



            <img src={LightLeft} className="w-full absolute z-50 top-0" />
            <div className="relative flex w-full  bg-white/5 overflow-hidden">

              <div className="snowing absolute top-0 left-0 w-full pointer-events-none z-10">
                <div className="small-snow">
                  <div className="small"></div>
                  <div className="small"></div>
                  <div className="medium"></div>
                  <div className="small"></div>
                  <div className="medium"></div>
                  <div className="small"></div>
                  <div className="small"></div>
                  <div className="medium"></div>
                  <div className="small"></div>
                  <div className="medium"></div>
                  <div className="small"></div>
                  <div className="medium"></div>
                  <div className="medium"></div>
                  <div className="medium"></div>
                  <div className="small"></div>
                  <div className="small"></div>
                  <div className="small"></div>
                  <div className="medium"></div>
                  <div className="small"></div>
                  <div className="medium"></div>
                </div>

              </div>

              <img src={LeftShadow} className="w-full absolute left-0 bottom-0" />


              <div className="flex flex-col z-20 w-full">
                <div className="w-full aspect-square bg-white/5 rounded border-4 border-secondary"></div>
                <div className="w-full  aspect-square bg-white/5 rounded  border-4 border-secondary"></div>
                <div className="w-full aspect-square bg-white/5 rounded border-4 border-secondary"></div>
              </div>

              <div className="flex flex-col z-20 w-full">
                <div className="w-full aspect-square bg-white/5 rounded border-4 border-secondary"></div>
                <div className="w-full  aspect-square bg-white/5 rounded  border-4 border-secondary"></div>
                <div className="w-full aspect-square bg-white/5 rounded border-4 border-secondary"></div>
              </div>
            </div>
          </div>
          <div className="w-full h-full  relative flex flex-col items-center justify-end px-8 lg:p-0 z-50">
            <Tree />
            <Giftbox isOpened={isGiftOpened} onOpen={(e) => onClickRedGiftBox(e)} />
            
          </div>


        </div>
        {/* end center */}

        {/* right start here */}
        <div className="flex-none hidden lg:flex items-end justify-center relative w-96 h-full ">

          <div className="bg-[#010225] w-44 h-full" />
          <img src={Fireplace} className="w-96 absolute bottom-0 z-10" />


          <div className="absolute bottom-0 w-40 aspect-square bg-orange-500/30 -translate-y-8 z-20  rounded-full blur-xl">

          </div>
          <div className="absolute bottom-10 z-30 ">
            <Lottie animationData={fire} className="h-40" />
          </div>

          <img src={ChairRight} className="scale-125 absolute -bottom-60 -right-1/3 z-50 " />

        </div>
        {/* right ends */}
      </div>




      <FadeInDialog isPresented={showAvailableGifts} setIsPresented={setShowAvailableGifts}>
        <div className="ring-1 ring-slate-900 mt-4  bg-secondary text-white rounded-lg shadow-xl flex flex-col items-start divide-y divide-slate-900">
          {
            walletAvailableGifts.map((g) => (

              <button onClick={()=> {openGiftBox(g)}} key={g.tokenId} className="flex items-center gap-5 p-5 hover:bg-black/25 w-full">
                <div className="bg-white rounded-lg flex items-center justify-center w-12 h-12">
                  <GiftIcon className="w-8 h-8 text-primary" />
                </div>
                <div>
                  <p className="font-medium text-white whitespace-nowrap">{g.name}</p>
                </div>
                <div className="ml-auto flex items-center justify-center">
                  <div className="rounded-full bg-[#08184F]/30 uppercase px-5 py-2 text-sm">
                    {g.isOpened ? "OPENED" : "UNOPENED"}

                  </div>
                </div>
              </button>
            ))
          }
        </div>
      </FadeInDialog>

      <FadeInDialog isPresented={isAlertNoAssetsPresented}>
        <div className="text-white font-medium flex items-center gap-4">
          <p>This wallet doesn't have any presents to open.</p>
        </div>
      </FadeInDialog>

      <FadeInDialog isPresented={isLoadingAssets} setIsPresented={setIsLoadingAssets}>
        <div className="text-white font-medium flex items-center gap-4">
          <Lottie animationData={LoadingAnimation} className="w-24 h-24 translate-x-10" />
          <p>We’re checking if you got lucky this year!</p>
        </div>
      </FadeInDialog>

      <FadeInDialog isPresented={isAlertPresented} setIsPresented={setIsAlertPresented}>
        <div className=" p-8 ring-1 ring-slate-900 mt-4  bg-secondary text-white rounded-lg shadow-xl flex flex-col items-center justify-center">
          <p className="mb-8">Please connect your wallet.</p>
          <button onClick={(e) => setIsWalletOptionsPresented(true)} className="focus:outline-none bg-primary hover:bg-[#049473] rounded-full text-white font-semibold text-sm py-3 px-6 ring-4 ring-primary ring-opacity-30" >
            Connect Wallet
          </button>
        </div>
      </FadeInDialog>

      <div id="floor" className="bg-white/[0.01] w-full h-20 lg:h-40 z-100">

      </div>

      <Transition
        show={isGiftOpened}
        as={Fragment}
        enter="ease-out duration-300 delay-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className={`fixed top-0 left-0 w-full h-full bg-black/90 flex items-center justify-center z-[999] p-8 transition-opacity opacity-0`}>

          <div className="w-full max-w-md flex flex-col transform overflow-hidden rounded-2xl bg-secondary shadow-xl transition-all">
            <div className="bg-primary aspect-square w-full">

            </div>
            <div className="p-5 text-white">
              <h3 className="text-xl font-semibold mb-4">
                A Gift for you!
              </h3>

              <p className="mb-6">You have received XXX. Thanks for being an important part of our community this holiday season</p>

              <button className="rounded w-full p-2 bg-primary hover:bg-[#049473] text-white text-sm font-medium" onClick={(e) => { setIsGiftOpened(false) }}>Close</button>
            </div>
          </div>
        </div>
      </Transition>

    </div>
  )
}