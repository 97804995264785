import TreeTrunk from '../assets/tree_trunk.svg'
import TreeBaseLayer from '../assets/tree_base_layer.svg'
import TreeTopLayer from '../assets/tree_top_layer.svg'
import WhiteNeoLogo from '../assets/white_neo_logo.svg'
import TreeBlurBackground from '../assets/tree_blur.svg'
import TreeImage from '../assets/Tree.svg'
export default function Tree(props) {

    return (
        <div id="tree" className="relative translate-y-8 flex flex-col items-center justify-center w-full">

            <img src={TreeImage} className="w-96" />
            {/* <img src={TreeTrunk} className="lg:w-28" />

            <div className="absolute bottom-28 flex items-center justify-center">
                <img src={WhiteNeoLogo} className="w-10 h-10 absolute -translate-x-1 -top-3 z-[100] drop-shadow-md" />
                <img src={TreeBlurBackground} className="z-10 absolute top-0 left-0 scale-110" />
                <img src={TreeBaseLayer} className="z-20 " />
                <img src={TreeTopLayer} className=" z-30 absolute top-0" />

                <div className='absolute w-full h-full z-50'>
                    
                        {props.children}
                    
                </div>
            </div> */}



        </div>
    )

}