import React from "react"
import { useWalletConnect, WitnessScope } from "@cityofzion/wallet-connect-sdk-react";

import { XMarkIcon } from '@heroicons/react/24/solid'

//local assets
import Chair from '../assets/v2/chair.svg'
import LeftWindow from '../assets/v2/leftwindow.svg'
import FirePlace from '../assets/v2/fireplace_with_light.svg'
import TreeWithBoxes from '../assets/v2/tree_with_boxes.svg'
import Wreath from '../assets/v2/wreath.svg'
import Lamp from '../assets/v2/lamp_with_tree_shadow.svg'
import LightFromWindow from '../assets/v2/light_from_window.svg'
import Floor from '../assets/v2/floor.svg'
import RedBox from '../assets/v2/redbox.svg'
import RedBoxLid from '../assets/v2/redbox_lid.svg'
import RedBoxLight from '../assets/v2/redbox_light.svg'
import { GiftIcon } from '@heroicons/react/24/outline'
import Lottie from "lottie-react";
import fire from "../assets/firelottie.json"
import LoadingAnimation from "../assets/loading.json"
import NeonWallet from '../assets/neon.svg'
import O3Wallet from '../assets/o3.png'
import NeoLineWallet from '../assets/neoline.png'
//local assets


//logos
import NeoLogo from '../assets/v2/logos/neo.png'
import CozLogo from '../assets/v2/logos/coz.png'
import FlamingoLogo from '../assets/v2/logos/flamingo.png'
import NNTLogo from '../assets/v2/logos/nnt.png'
import NeoLineLogo from '../assets/v2/logos/neoline.png'
import BlockchainCuties from '../assets/v2/logos/BlockchainCuties.png'
import GhostMarket from '../assets/v2/logos/GhostMarket.png'
import NeoCandy from '../assets/v2/logos/NeoCandy.png'
import DogeRift from '../assets/v2/logos/DogeRift.png'
import QSC from '../assets/v2/logos/QSC.png'
import ForTheWin from '../assets/v2/logos/ForTheWin.png'
import ILEX from '../assets/v2/logos/ILEX.png'
import Meme2Earn from '../assets/v2/logos/Meme2Earn.png'
import SuperNova from '../assets/v2/logos/SuperNova.png'


import confetti from "canvas-confetti";
import { Fragment } from 'react'
import { Transition } from '@headlessui/react'

import ResultDialog from "./ResultDialog";
import { FadeInDialog } from "../Components/FadeInDialog";
import LogoOnTree from "./LogoOnTree";

import { Package, types, helpers } from '@cityofzion/props'
import { default as Neon, tx,wallet } from "@cityofzion/neon-js";
import { sleep } from "@cityofzion/props/dist/helpers";
import { Tokens } from "./conts";
import { useN3WalletContext } from "../contexts/N3WalletContext";

export default function HomeV2(props) {


    const packageMainNet = "5728017130c213cbc369c738f470d66628e5acf2"
    const chestMainNet = "9378d9f8add6e1d47e7af4d75c121a11a5e9f929"
    const chestId = 2 //mainnet = 2, testnet = 1
    const network = types.NetworkOption.MainNet
    const contractMethod = 'loot_chest'

    const wcSdk = useWalletConnect()

    const [isEligible, setIsEligible] = React.useState(false)
    const [isGiftOpened, setIsGiftOpened] = React.useState(false)
    const [isWalletOptionsPresented, setIsWalletOptionsPresented] = React.useState(false)
    const [address, setAddress] = React.useState(null)
    const [isConnected, setIsConnected] = React.useState(false)

    const [isAlertPresented, setIsAlertPresented] = React.useState(false)
    const [isLoadingAssets, setIsLoadingAssets] = React.useState(false)
    const [isAlertNoAssetsPresented, setIsAlertNoAssetsPresented] = React.useState(false)
    const [showAvailableGifts, setShowAvailableGifts] = React.useState(false)
    const [waitingForResponseFromWallet, setWaitingForResponseFromWallet] = React.useState(false)

    const [isCheckingResult, setIsCheckingResult] = React.useState(false)
    const [receivedToken, setReceivedToken] = React.useState(null)
    const [packageTokens, setPackageTokens] = React.useState(null)
    const [connectedWalletProtocol, setConnectedWalletProtocol] = React.useState(null) //dapi or wc

    //this is for dAPI
    const n3WalletContext = useN3WalletContext()

    const connectWalletConnect = async () => {
        if (!wcSdk.isConnected()) {
            await wcSdk.connect('neo3:mainnet')
        }
    }

    const connectWalletDApi = async () => {
        n3WalletContext.initNeoLine()
    }

    React.useEffect(() => {

        if (n3WalletContext.connected) {
            setConnectedWalletProtocol("dapi")
            setIsConnected(n3WalletContext.connected)
            start(n3WalletContext.account.address)
        }
    }, [n3WalletContext.connected])

    const start = async (address) => {
        //1. Checking user's asset 
        //2. If user has nep11 puppet then check if what key user has
        //3. get token id and check eligibility
        // console.log("Checking assets of address ", address)
        setAddress(address)
        loadAssets(address)
    }


    //1. load assets after wallet is connected (able to read wallet's address)
    const loadAssets = async (address) => {
        //loading
        setIsLoadingAssets(true)
        const pk = await new Package({
            network: network
        })
        await pk.init()


        const tokenList = await pk.tokensOf(address)
        setPackageTokens(tokenList)

        var tokenInfos = await Promise.all(tokenList.map(async (t) => {
            const tokenId = t
            const tokenInfo = await pk.getTokenJSON(tokenId)
            return tokenInfo
        }))
        setWalletAvailableGifts(tokenInfos)
    }

    //2. when we get nep11 balances
    React.useEffect(() => {

        if (address === null) {
            return
        }

        if (packageTokens === null) {
            setIsLoadingAssets(false)
            setIsEligible(false)
            return
        }

        //if user doesn't have any Package tokens meaning that this wallet doesn't have puppet
        if (packageTokens.length === 0) {
            // console.log(address, " doesn't have any asset")
            setIsLoadingAssets(false)
            setIsEligible(false)
            return
        }

        setIsEligible(true)
        setIsLoadingAssets(false)

    }, [packageTokens])

    //0. once connected, we are able to read a user's address then start checking.
    React.useEffect(() => {
        if (!wcSdk.isConnected()) {
            return
        }
        setConnectedWalletProtocol("wc")
        setIsConnected(wcSdk.isConnected())
        const address = wcSdk.getAccountAddress()
        start(address)
    }, [wcSdk.isConnected()])

    const disconnect = async () => {
        //reset all values
        setAddress(null)
        setPackageTokens(null)
        setIsEligible(false)
        setIsConnected(false)
        setConnectedWalletProtocol(null)

        if (connectedWalletProtocol === "wc") {
            if (!wcSdk.isConnected()) {
                return
            }
            wcSdk.disconnect()
        } else if (connectedWalletProtocol === "dapi") {

        }

    }

    const checkAndShowResult = async (gift) => {
        const tokenId = gift.tokenId;
        //show loading screen
        setIsCheckingResult(true)

        await sleep(25000)

        let transactionsResponse = await fetch(`https://dora.coz.io/api/v1/neo3/mainnet/address_txfull/${address}/1`)
        const transactions = await transactionsResponse.json();

        if (transactions && transactions.items.length > 0) {
            let latestTx = transactions.items[0]
            if (latestTx.transfers.length > 0) {
                let transfer = latestTx.transfers[0]
                let asset = transfer.scripthash
                let amount = Number(transfer.amount)
                let token = Tokens[asset]
                if (token) {
                    var formattedAmount = amount
                    if (token.decimals > 0) {
                        formattedAmount = amount / Math.pow(10, token.decimals)
                    }
                    setReceivedToken({
                        token: token,
                        amount: formattedAmount.toLocaleString()
                    })
                }
            }
        }

        //show loading screen
        setIsCheckingResult(false)

        const list = walletAvailableGifts
        const g = list.find(a => a.tokenId === tokenId)
        g.state = "opened"
        setWalletAvailableGifts(list)
        confetti({
            particleCount: 200,
            startVelocity: 80,
            angle: 90,
            spread: 50,
            origin: {
                x: 0.5,
                y: 0.985,
            }
        })

        //show dialog to show result
        setIsGiftOpened(true)
    }

    const invokeDapi = async (gift) => {
        const tokenId = gift.tokenId;
        if (gift.state === "opened") {
            return;
        }

        setShowAvailableGifts(false)
        setWaitingForResponseFromWallet(true)

        n3WalletContext.invoke({
            scriptHash: chestMainNet,
            operation: contractMethod,
            args: [
                { type: 'Integer', value: chestId },
                        { type: 'Hash160', value: packageMainNet },
                        { type: 'String', value: tokenId.toString() },
            ],
            signers: [
                {
                    account: wallet.getScriptHashFromAddress(address),
                    scopes: 1,
                }
            ]
        })
        .then(result => {
            // console.log('Invoke transaction success!');
            // console.log('Transaction ID: ' + result.txid);
            // console.log('RPC node URL: ' + result.nodeURL);
            setWaitingForResponseFromWallet(false)
            checkAndShowResult(gift)
        })
        .catch((error) => {
            setWaitingForResponseFromWallet(false)
            console.log(error)
        })
    }

    const invokeWalletConnect = async (gift) => {
        const tokenId = gift.tokenId;
        if (gift.state === "opened") {
            return;
        }

        setShowAvailableGifts(false)
        setWaitingForResponseFromWallet(true)
        try {
            
            const txid = await wcSdk.invokeFunction({
                invocations: [{
                    scriptHash: chestMainNet,
                    operation: contractMethod,
                    args: [
                        { type: 'Integer', value: chestId },
                        { type: 'Hash160', value: packageMainNet },
                        { type: 'String', value: tokenId.toString() },
                    ]
                }],
                signers: [{
                    scopes: tx.WitnessScope.Global
                }]
            })

            setWaitingForResponseFromWallet(false)

            checkAndShowResult(gift)

        } catch (error) {
            setWaitingForResponseFromWallet(false)
            console.log(error)
        }
    }

    const openGiftBox = async (gift) => {
        
        if (connectedWalletProtocol === "dapi") {
            invokeDapi(gift)
        } else if (connectedWalletProtocol === "wc") {
            invokeWalletConnect(gift)
        }
        
    }

    const onClickRedGiftBox = async (e) => {

        //if user doesn't connect wallet yet then alert a dialog telling them to connect.
        if (address === null) {
            setIsAlertPresented(true);
            return
        }

        //if user is not eligible then alert
        if (isEligible === false) {
            setIsAlertNoAssetsPresented(true)
            setTimeout(() => {
                setIsAlertNoAssetsPresented(false)
            }, 2000);
            return
        }

        setShowAvailableGifts(true)
    }

    const truncateMiddle = (string) => {
        return `${string.substring(0, 4)}...${string.substring((string.length - 4))}`
    }


    const [walletAvailableGifts, setWalletAvailableGifts] = React.useState([])


    return (
        <div className='bg-[#010225] absolute inset-0 w-full h-full overflow-hidden flex flex-col items-center justify-end'>

            <ResultDialog isPresented={isGiftOpened} result={receivedToken} onClose={() => { setIsGiftOpened(false) }} />

            <Transition
                show={isWalletOptionsPresented}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
                as={Fragment}
            >
                <div className={`fixed top-0 left-0 w-full h-full bg-black/90 flex items-center justify-center z-[999] p-8 transition-opacity opacity-0`}>

                    <div className="w-60 ring-1 ring-slate-900 mt-4  bg-secondary text-white rounded-lg shadow-xl flex flex-col items-start divide-y divide-slate-900">
                        <div className="px-4 py-4 font-medium w-full flex items-center">
                            <p>Connect Wallet</p>
                            <button onClick={(e) => setIsWalletOptionsPresented(false)} className="ml-auto"><XMarkIcon className="w-5 h-5" /></button>
                        </div>
                        <button onClick={(e) => { setIsWalletOptionsPresented(false); connectWalletConnect(e) }} className="py-4 px-4 flex items-center gap-2 w-full hover:bg-black/25">
                            <img src={NeonWallet} className="w-8 h-8 rounded" />
                            NeonWallet
                        </button>
                        {/* <button onClick={(e) => { setIsWalletOptionsPresented(false); connectWalletDApi(e) }} className="py-4 px-4 flex items-center gap-2 w-full hover:bg-black/25">
                            <img src={O3Wallet} className="w-8 h-8  rounded" />
                            O3
                        </button> */}
                        <button onClick={(e) => { setIsWalletOptionsPresented(false); connectWalletDApi(e) }} className="py-4 px-4 flex items-center gap-2 w-full hover:bg-black/25">
                            <img src={NeoLineWallet} className="w-8 h-8  rounded" />
                            NeoLine
                        </button>
                    </div>
                </div>
            </Transition>

            {/* room line */}
            <div className='fixed bottom-0 h-80 lg:h-60 w-full rotate-[0.47deg]'>
                <div className='flex flex-col w-full'>
                    <div className='bg-[#84AFE3]/60 w-full h-3 z20'></div>
                    <div className='bg-[#1F1F4D] w-full h-8 z-20'></div>
                    <img src={Floor} className="absolute w-full lg:top-6 left-0 z-10" />
                </div>
            </div>
            {/* end room line */}

            <nav className="lg:fixed top-0 container max-w-6xl mx-auto flex items-center py-8 px-8 z-50 pointer-events-none">
                <div className="z-">
                    <img src="neo-logo.svg" className="h-8" />
                </div>
                <div className="ml-auto pointer-events-auto">
                    {
                        isConnected ?
                            <div className="flex flex-col items-end">
                                <div className="text-white flex items-center bg-primary rounded-full px-6 py-3 ring-4 ring-primary ring-opacity-30">
                                    <p className="text-sm text-white mr-4">{truncateMiddle(address)}</p>
                                    <button onClick={(e) => { disconnect() }} className="flex items-center ">
                                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6 16H2.66667C2.22464 16 1.80072 15.8244 1.48816 15.5118C1.17559 15.1993 1 14.7754 1 14.3333V2.66667C1 2.22464 1.17559 1.80072 1.48816 1.48816C1.80072 1.17559 2.22464 1 2.66667 1H6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M11 12.6667L15.1667 8.50004L11 4.33337" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M15.1667 8.5H5.16669" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>

                                    </button>
                                </div>

                            </div>
                            :
                            <button onClick={(e) => setIsWalletOptionsPresented(true)} className="focus:outline-none bg-primary hover:bg-[#049473] rounded-full text-white font-semibold text-sm py-3 px-6 ring-4 ring-primary ring-opacity-30" >
                                Connect Wallet
                            </button>
                    }

                </div>
            </nav>

            <div className='h-full w-full container mx-auto text-white flex items-end justify-center z-20 '>

                {/* left */}
                <div className='h-full w-80 flex-none hidden lg:flex items-center justify-center '>

                    <div className='fixed bottom-[400px] w-full flex items-center justify-center'>
                        <img src={LeftWindow} className=" z-10" />
                        <img src={LightFromWindow} className=" absolute top-0 translate-x-1/3 translate-y-36 z-50 " />
                    </div>
                    <img src={Chair} className="absolute bottom-0 z-[110] -translate-x-1/4 -translate-y-12" />
                </div>
                {/* left */}

                {/* center */}
                <div className='w-full h-full flex items-end justify-center'>

                    <img src={Lamp} className="absolute bottom-0 z-100 -translate-y-20 lg:-translate-y-36 -translate-x-1/2" />

                    <div className=' flex items-center justify-center w-full h-full translate-y-24 lg:translate-y-16 '>




                        <img src={TreeWithBoxes} className="absolute bottom-0 z-[100] max-w-none" />




                        <div className="w-96 h-96 -translate-y-72 absolute bottom-0 z-[1110] ">

                            <a href="https://neo.org" target="_blank" className="z-[2000] absolute -top-20 left-40 w-12 h-12 bg-transparent">
                                &nbsp;
                            </a>

                            <LogoOnTree image={NNTLogo} name="Neo News Today" url="https://neonewstoday.com/" className="top-4 left-24" />
                            <LogoOnTree image={CozLogo} name="COZ" url="https://coz.io" className="top-0 right-28" />

                            <LogoOnTree image={FlamingoLogo} name="Flamingo" url="https://flamingo.finance/" className="top-20 left-40" />
                            <LogoOnTree image={NeoLineLogo} name="NeoLine" url="https://neoline.io/en/" className="top-20 right-20" />

                            <LogoOnTree image={SuperNova} name="SuperNova" url="https://supernovashards.world/" className="top-40 left-10 -translate-y-2" />
                            <LogoOnTree image={BlockchainCuties} name="Blockchain Cuties" url="https://blockchaincuties.com/" className="top-40 left-40" />
                            <LogoOnTree image={GhostMarket} name="GhostMarket" url="https://ghostmarket.io/" className="top-40 right-10" />

                            <LogoOnTree image={NeoCandy} name="NeoCandy" url="https://neocandy.io/" className="bottom-28 left-24" />
                            <LogoOnTree image={DogeRift} name="DogeRift" url="https://dogerift.com/" className="bottom-28 right-28" />

                            <LogoOnTree image={QSC} name="Quirky Soul College" url="https://www.quirkysoulcollege.com/" className="bottom-10 left-0" />
                            <LogoOnTree image={ForTheWin} name="ForTheWin Network" url="https://www.forthewin.network/" className="bottom-8 left-24" />
                            <LogoOnTree image={ILEX} name="ILEX" url="https://www.ilexnft.com/" className="bottom-8 right-32" />
                            <LogoOnTree image={Meme2Earn} name="Meme2Earn" url="https://nudes.army/" className="bottom-10 right-0" />

                        </div>


                        <div id="redbox" className='z-[110] absolute bottom-0 -translate-y-36 group flex flex-col items-center justify-center'>

                            <img src={RedBoxLid} className="translate-y-[164px] z-[200] group-hover:translate-y-[136px] duration-500 ease-in-out" />

                            <img src={RedBoxLight} className="translate-y-[30px] translate-x-[5px] opacity-0 group-hover:opacity-100 duration-500 ease-in-out" />
                            <img src={RedBox} />

                            <button onClick={(e) => { onClickRedGiftBox(e) }} className=" z-50 absolute -bottom-6 left-24  rounded-full bg-white w-14 h-14 drop-shadow-sm animate-bounce flex items-center justify-center ring-4 ring-white ring-opacity-50">
                                <GiftIcon className="w-10 h-8 text-primary" />
                            </button>

                        </div>
                    </div>

                </div>
                {/* center */}

                {/* right */}
                <div className='h-full w-80 flex-none  hidden lg:flex items-center justify-center '>

                    <div className='w-full'>
                        <img src={Wreath} className="translate-x-20 absolute bottom-[500px]" />
                        <img src={FirePlace} className="absolute bottom-0 -translate-y-[100px] " />
                        <div className='w-80 h-60  absolute bottom-0 -translate-y-1/2 translate-x-8 flex items-center justify-end '>
                            <Lottie animationData={fire} className=" scale-50 translate-x-1" />
                        </div>


                    </div>
                </div>
                {/* right */}
            </div>


            <FadeInDialog isPresented={showAvailableGifts} setIsPresented={setShowAvailableGifts}>
                <div className="ring-1 ring-slate-900 mt-4  bg-secondary text-white rounded-lg shadow-xl flex flex-col items-start divide-y divide-slate-900 max-h-96 overflow-y-auto">
                    <div className="font-medium p-5 sticky top-0 bg-secondary w-full">{walletAvailableGifts.length} available</div>
                    {
                        walletAvailableGifts.map((g) => (

                            <button onClick={() => { openGiftBox(g) }} key={g.tokenId} className="flex items-center gap-5 p-5 hover:bg-black/25 w-full">
                                <div className="flex-none bg-white rounded-lg flex items-center justify-center w-8 h-8 lg:w-12 lg:h-12">
                                    <GiftIcon className="w-6 h-6 lg:w-8 lg:h-8 text-primary" />
                                </div>
                                <div>
                                    <p className="font-medium text-white whitespace-nowrap">{g.name} {g.tokenId}</p>
                                </div>
                                <div className="ml-auto flex items-center justify-center">
                                    <div className="rounded-full bg-[#08184F]/30 uppercase px-5 py-2 text-sm">
                                        {g.state === "closed" ? "UNOPENED" : "OPENED"}
                                    </div>
                                </div>
                            </button>
                        ))
                    }
                </div>
            </FadeInDialog>


            <FadeInDialog isPresented={isCheckingResult}>
                <div className="text-white font-medium flex items-center gap-4">
                    <Lottie animationData={LoadingAnimation} className="w-24 h-24 translate-x-10" />
                    <p>We found something. Let’s see what you got!</p>
                </div>
            </FadeInDialog>

            <FadeInDialog isPresented={waitingForResponseFromWallet}>
                <div className="text-white font-medium flex items-center gap-4">
                    <p>Please approve the transaction in your wallet</p>
                </div>
            </FadeInDialog>

            <FadeInDialog isPresented={isAlertNoAssetsPresented}>
                <div className="text-white font-medium flex items-center gap-4">
                    <p>This wallet doesn't have any presents to open.</p>
                </div>
            </FadeInDialog>

            <FadeInDialog isPresented={isLoadingAssets} setIsPresented={setIsLoadingAssets}>
                <div className="text-white font-medium flex items-center gap-4">
                    <Lottie animationData={LoadingAnimation} className="w-24 h-24 translate-x-10" />
                    <p>We’re checking if you got lucky this year!</p>
                </div>
            </FadeInDialog>

            <FadeInDialog isPresented={isAlertPresented} setIsPresented={setIsAlertPresented}>
                <div className=" p-8 ring-1 ring-slate-900 mt-4  bg-secondary text-white rounded-lg shadow-xl flex flex-col items-center justify-center">
                    <p className="mb-8">Please connect your wallet.</p>
                    <button onClick={(e) => setIsWalletOptionsPresented(true)} className="focus:outline-none bg-primary hover:bg-[#049473] rounded-full text-white font-semibold text-sm py-3 px-6 ring-4 ring-primary ring-opacity-30" >
                        Connect Wallet
                    </button>
                </div>
            </FadeInDialog>

        </div>
    )
}