import { GiftIcon } from '@heroicons/react/24/outline'
import Light from '../assets/light_for_opened_box.svg'
import Tag from '../assets/tag.svg'
export default function Giftbox(props) {


    const { isOpened } = props

    return (
        <div id="giftbox" className="group duration-100 translate-y-3 ease-in-out  z-50 flex flex-col items-center drop-shadow-lg">
            <button onClick={(e) => { props.onOpen(e) }} className=" z-50 absolute -bottom-6  rounded-full bg-white w-12 h-12 drop-shadow-sm animate-bounce flex items-center justify-center ring-4 ring-white ring-opacity-50">

                <GiftIcon className="w-8 h-8 text-primary" />
            </button>

            
            {/* this is a lid */}
            <div className={`overflow-hidden w-48 h-5 bg-[#DF3B3B] drop-shadow-lg flex items-center justify-center z-50 duration-500 ease-in-out group-hover:-translate-y-6  ${isOpened ? " -translate-x-1/2 translate-y-4 -rotate-[38deg]" : ""} `}>
                <div className="h-full w-6 bg-[#AC2929] opacity-50 absolute left-0 top-0"></div>
                
            </div>
            {/* <img src={Tag} className="absolute top-0 right-6 z-50 group-hover:-translate-y-6"/> */}

            <img className='absolute bottom-8 scale-150 opacity-0 group-hover:opacity-100 duration-200 ease-in-out' src={Light} />

            {/* this is a box */}
            <div className="w-44 h-16 bg-[#DF3B3B] flex items-center justify-center relative overflow-hidden">
                <div className="w-full bg-[#A42525] h-4 -skew-y-2 absolute -top-1 bg-opacity-50"></div>
                <div className="h-full w-4 bg-[#AC2929] opacity-50 absolute left-0 top-0"></div>

            </div>

            
            
        </div>
    )

}