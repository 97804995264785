import { Transition } from "@headlessui/react"
import { Fragment } from "react"
import ResultHero from "../assets/v2/resulthero.png"
export default function ResultDialog(props) {

  const { isPresented, onClose, result } = props


  return (
    <Transition
      show={isPresented}
      as={Fragment}
      enter="ease-out duration-300 delay-200"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className={`fixed top-0 left-0 w-full h-full bg-black/90 flex items-center justify-center z-[999] p-8 transition-opacity opacity-0`}>

        <div className="w-80 flex flex-col transform overflow-hidden rounded-2xl bg-secondary shadow-xl transition-all">
          <div className="bg-secondary w-full">
            <img src={ResultHero} />
          </div>
          <div className="p-5 text-white">
            <h3 className="text-xl font-semibold mb-4">
            {result && result.token.title}
            </h3>

            {
              result === null ?
                <p className="mb-6">Thanks for being an important part of our community this holiday season.</p>
                :
                <div className="mb-6 font-normal">
                  <div dangerouslySetInnerHTML={{__html:result.token.message.replace("{amount}",result.amount)}}></div>
                </div>
            }

            <button className="rounded w-full p-2 bg-primary hover:bg-[#049473] text-white text-sm font-medium" onClick={(e) => { onClose() }}>Close</button>
          </div>
        </div>
      </div>
    </Transition>
  )
}