export default function Box(props) {

    const {color, lidW, w, h, className} = props

    return (
        <div className={` group duration-100 ease-in-out z-50 flex flex-col items-center overflow-hidden ${className}`}>

            {/* this is a lid */}
            <div className={`${lidW} h-5 ${color}  flex items-center justify-center z-50 duration-500 ease-in-out overflow-hidden drop-shadow-lg`}>
                <div className={`h-5 w-6 ${color} brightness-50 opacity-30 absolute left-0 top-0`}></div>
            </div>

            {/* this is a box */}
            <div className={`${w} ${h} ${color}  flex items-center justify-center relative overflow-hidden`}>
                <div className={`w-full  ${color} brightness-50 h-4 -skew-y-2 absolute -top-1 bg-opacity-30`}></div>
                <div className={`h-full w-4  ${color} brightness-50 opacity-30 absolute left-0 top-0`}></div>

            </div>



        </div>
    )

}