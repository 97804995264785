export const Tokens = {
    "0x24fc2b1bd77778dbbefb7c9c4fdb020a7aba6986": {
        project:"Quirky Soul College",
        symbol:"QSC",
        name: "Quirky Soul",
        decimals: 0,
        title:"Inside the box is...",
        message:`A <span class="font-semibold">Quirky Soul NFT</span> gifted to you by <a href="https://www.quirkysoulcollege.com/" target="_blank" class="font-semibold">Quirky Soul College</a>. Happy holidays!`
    },
    "0xdd191554eb403669221137aee0c424c27670cb2b": {
        project:"Quirky Soul College",
        symbol:"BSS",
        name: "Black Stone Shard",
        decimals: 0,
        title:"Inside the box is...",
        message:`A <span class="font-semibold">Black Stone Shard NFT</span> gifted to you by <a href="https://www.quirkysoulcollege.com/" target="_blank" class="font-semibold">Quirky Soul College</a>. Happy holidays!`
    },
    "0xc09af9967175faca47d610716900f075bd8bf18a": {
        project:"Quirky Soul College",
        symbol:"MFS",
        name: "Mythic Fancy Skin",
        decimals: 0,
        title:"Inside the box is...",
        message:`A <span class="font-semibold">Mythic Fancy Skin NFT</span> gifted to you by <a href="https://www.quirkysoulcollege.com/" target="_blank" class="font-semibold">Quirky Soul College</a>. Happy holidays!`
    },
    "0x340720c7107ef5721e44ed2ea8e314cce5c130fa": {
        project:"Meme2Earn",
        symbol:"NUDES",
        name: "Nudes Token",
        decimals: 8,
        title:"Inside the box is...",
        message:`<span class="font-semibold">{amount} NUDES</span> tokens gifted to you by <a href="https://nudes.army/" target="_blank" class="font-semibold">Meme2Earn</a>. Happy holidays!`
    },
    "0xf0151f528127558851b39c2cd8aa47da7418ab28": {
        project:"Flamingo",
        symbol:"FLM",
        name: "Flamingo Token",
        decimals: 8,
        title:"Inside the box is...",
        message:`<span class="font-semibold">{amount} FLM</span> tokens gifted to you by <a href="https://flamingo.finance/" target="_blank" class="font-semibold">Flamingo Finance</a>. Happy holidays!`
    },
    "0x9f344fe24c963d70f5dcf0cfdeb536dc9c0acb3a": {
        project:"ILEX",
        symbol:"ILEX",
        name: "Ilex Poleman NFT",
        decimals: 0,
        title:"Inside the box is...",
        message:`An <span class="font-semibold">Ilex Poleman NFT</span> gifted to you by <a href="https://www.ilexnft.com/" target="_blank" class="font-semibold">ILEX</a>. Happy holidays!`
    },
    "0xcdbb7bd33c623510f94626d1953f95a59c27645c": {
        project:"NeoCandy",
        symbol:"LOLLI",
        name: "Lollipop NFT",
        decimals: 0,
        title:"Inside the box is...",
        message:`A <span class="font-semibold">Lollipop NFT</span> gifted to you by <a href="https://neocandy.io/" target="_blank" class="font-semibold">NeoCandy</a>. Happy holidays!`
    },
    "0x88da18a5bca86ec8206d9b4960a7d0c4355a432f": {
        project:"NeoCandy",
        symbol:"CANDY",
        name: "Candy Token",
        decimals: 9,
        title:"Inside the box is...",
        message:`<span class="font-semibold">{amount} CANDY</span> tokens gifted to you by <a href="https://neocandy.io/" target="_blank" class="font-semibold">NeoCandy</a>. Happy holidays!`
    },
    "0xcab9f861120e6fe725995878c2024e66c0be1306": {
        project:"Forthewin",
        symbol:"BOYZ",
        name: "NeoBoyz NFT",
        decimals: 0,
        title:"Inside the box is...",
        message:`A <span class="font-semibold">NeoBoyz NFT</span> gifted to you by <a href="https://www.forthewin.network/" target="_blank" class="font-semibold">Forthewin Network</a>. Happy holidays!`
    },
    "0x8a8c112e35d77238c2e7baeacb3dc253f6213a1e": {
        project:"Forthewin",
        symbol:"KEY",
        name: "Locker Key NFT",
        decimals: 0,
        title:"Inside the box is...",
        message:`A <span class="font-semibold">FTW Locker Key NFT</span> gifted to you by <a href="https://www.forthewin.network/" target="_blank" class="font-semibold">Forthewin Network</a>. Happy holidays!`
    },
    "0x322b5a366ca724801a1aa01e669b5f3d7f8c7f6f": {
        project:"DogeRift",
        symbol:"DOGER",
        name: "DOGER Token",
        decimals: 8,
        title:"Inside the box is...",
        message:`<span class="font-semibold">{amount} DOGER</span> tokens gifted to you by <a href="https://dogerift.com/" target="_blank" class="font-semibold">DogeRift</a>. Happy holidays!`
    },
    "0x9b049f1283515eef1d3f6ac610e1595ed25ca3e9": {
        project:"GhostMarket",
        symbol:"GM",
        name: "GhostMarket Token",
        decimals: 8,
        title:"Inside the box is...",
        message:`<span class="font-semibold">{amount} GM</span> tokens gifted to you by <a href="https://ghostmarket.io/" target="_blank" class="font-semibold">GhostMarket</a>. Happy holidays!`
    },
    "0xd2a4cff31913016155e38e474a2c06d08be276cf": {
        project:"NGD",
        symbol:"GAS",
        name: "GAS",
        decimals: 8,
        title:"Inside the box is...",
        message:`<span class="font-semibold">{amount} GAS</span> tokens gifted to you by <a href="https://neoline.io/en/" target="_blank" class="font-semibold">NeoLine</a>, <a href="https://neo.org/" target="_blank" class="font-semibold">NGD</a>, and <a href="https://neonewstoday.com/" target="_blank" class="font-semibold">Neo News Today</a>. Happy holidays!`
    },
    "0xf456649d0b8f331596035a07f977cb8d8dbf0122": {
        project:"Blockchain Cuties",
        symbol:"CUTIES",
        name: "N3 Christmas NFT",
        decimals: 0,
        title:"Inside the box is...",
        message:`A <span class="font-semibold">Neo Christmas Cutie NFT</span> gifted to you by <a href="https://blockchaincuties.com/" target="_blank" class="font-semibold">Blockchain Cuties</a>. Happy holidays!`
    },
    "0xaab90afe15c791872fcd2f490346682294b436ed": {
        project:"Supernova",
        symbol:"LFC",
        name: "Life Coin Tokens",
        decimals: 9,
        title:"Inside the box is...",
        message:`<span class="font-semibold">{amount}</span> LFC tokens gifted to you by <a href="https://supernovashards.world/" target="_blank" class="font-semibold">Supernova</a>. Happy holidays!`
    }
}